import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { isFranchisor, MenuService } from '@tuacar-workspace/core';
import { UiModule } from '@tuacar-workspace/ui';

@Component({
  selector: 'tuacar-menu',
  standalone: true,
  imports: [CommonModule, UiModule, RouterModule],
  template: ` @let isFranchisor = isFranchisor$ | async;
    <ul
      nz-menu
      nzTheme="dark"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nz-menu-item [routerLink]="'/statistiche/dashboard'" nzMatchRouter>
        <i nz-icon nzType="dashboard" nzTheme="outline"></i>
        <span>Dashboard</span>
      </li>
      <li
        nz-menu-item
        [routerLink]="'/statistiche/venduto/agenzie'"
        nzMatchRouter
      >
        <i nz-icon nzType="dashboard" nzTheme="outline"></i>
        @if (isFranchisor) {
          <span>Venduto agenzie</span>
        } @else {
          <span>Venduto</span>
        }
      </li>
      <li
        *ngIf="isFranchisor"
        nz-menu-item
        [routerLink]="'/statistiche/venduto/franchisor'"
        nzMatchRouter
      >
        <i nz-icon nzType="dashboard" nzTheme="outline"></i>
        <span>Venduto</span>
      </li>
      <li nz-menu-item [routerLink]="'/statistiche/garanzie'" nzMatchRouter>
        <i nz-icon nzType="dashboard" nzTheme="outline"></i>
        <span>Garanzie</span>
      </li>
    </ul>`,
  styles: [],
})
export class StatisticheMenuComponent {
  private store = inject(Store);

  isCollapsed = false;
  isFranchisor$ = this.store.select(isFranchisor);

  constructor(private menuService: MenuService) {
    this.menuService.isCollapsedChange$.subscribe((isCollapsed) => {
      this.isCollapsed = isCollapsed;
    });
  }
}
